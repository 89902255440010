@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

/* ESTILOS GENERALES */
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body {
  background-image: url('./imagenes/fondo_web_murales.png');
  background-repeat:repeat-y;
  background-position-x:center;
  background-attachment: fixed;
  height: 100%;
}


/* ESTILOS PANTALLA INICIO */
.estilo_contenedor_header {
  height: 56px;
}

.estilo_barra_header {
  background-color: #E09F3E;
  max-width:1920px;
  margin: 0 auto;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.16);
}

.estilo_caja_superior {
  height: 56px;
}

.estilo_inicio {
  max-width: 1920px;
}


.estilo_proyecto {
  text-align: justify;
  max-width: 1920px;
  font-family: 'Montserrat', sans-serif;
}

.estilo_layout_contenido {
  text-align: justify;
  max-width: 1920px;
  font-family: 'Montserrat', sans-serif;
}

.estilo_cabeceras_equipo {
  background-color: #AFD5A8 !important;
  padding: .4em 1em 0.1em 1em !important;
}

.estilo_texto_nombre {
  padding-top: .4em;
  font-weight: 800;
}

.estilo_boton_inicio :hover {
  background-color: #540B0E !important;
  color: white !important;
}

/* ESTILOS GALERÍAS FOTOGRÁFICAS */
.estilo-card-img-gallery :hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

.estilo_img_carrusel_fichas {
  max-height: 540px;
  margin:auto;
  cursor: pointer;
}

.div_contenedor_video_multimedia {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.video_multimedia {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.estilo_contenedor_footer {
  height: 110px;
}

.estilo_barra_footer {
  background-color: #000000;
  max-width:1920px;
  margin: 0 auto;
}
/* FIN ESTILOS PANTALLA INICIO */

.estilo_fuente_menu {
  font-family: 'Montserrat', sans-serif;
}

.estilo_contenedor_breadcrumb_footer {
  max-width: 1920px;
  background-color: #37E0A5;
  height: 110px;
  box-shadow: 0px -4px 5px 0px rgba(0,0,0,0.16);
}

.estilo_breadcrumb_footer {
  font-family: 'Archivo Narrow', sans-serif;
  font-size: 14px;
  color: #000000;
}

.estilo_breadcrumb_footer a {
  text-decoration: none;
  color: #000000;
}

.estilo_breadcrumb_footer :hover {  
  color: #000000;
}

.estilo_fondo_negro_semitransparente {
  background-image: url('./imagenes/fondo_negro_semitransparente.png');
  color: #ffffff;
}

.estilo_titulo_pagina_principal {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  color: #37E0A5;
  text-shadow: 1px 1px 2px rgb(0, 0, 0), 0 0 1em rgb(1, 10, 6), 0 0 0.2em rgb(4, 4, 12);
 }

.estilo_items_pagina {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  color: #AFD5A8;
}

.estilo_cabecera_modal_fotos {
  background-color: #AFD5A8;
}

.estilo_texto_cabecera_modal_fotos {
  font-weight: 800;
}

.estilo_boton_sintesis_curricular {
  font-family: 'Archivo Narrow', sans-serif;
  font-size: 14px;
  color:#ffffff;
}

.estilo_cabecera_modal_equipo {
  background-color: #AFD5A8;
}

.estilo_titulos_subtitulos_base {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  text-shadow: 1px 1px 2px rgb(0, 0, 0), 0 0 1em rgb(1, 10, 6), 0 0 0.2em rgb(4, 4, 12);
}

.estilo_verde_claro {
  color: #AFD5A8;
}

.estilo_verde_oscuro {
  color: #37E0A5;
}

.estilo_fondo_opciones {
  background-color: #AFD5A8;
}

.estilo_link_restaurar_mapa {  
  color:#3c3c3c;
}

.estilo_link_restaurar_mapa :hover {  
  color: #000000;
  text-decoration: underline;
}

.estilo_div_visor_fichas {
  max-width: 1920px;
  width: 100%;
  height: 100vh; 
  display: flex;
  flex-direction: column;
}

.webviewer {
  flex: 1;
  margin: 4px 0 10px 0;
}

/* ESTILOS VISOR DE IMAGENES */
.estilo_div_principal_laminas {
  width: 100%;
  height: 100vh; 
  display: flex;
  flex-direction: column;
}

.estilo-contenedor-visor-laminas {
  max-width: 1920px;
  width: 100%;
  height: 100%;
  overflow: auto;
  border: 1px solid #ffffff;
  background-color: #191B1F;
}

.estilo_visor_laminas {
  width: 100%;
  object-fit: cover;
  transform-origin: 0 0;
}

.estilo_barra_controles_visor_laminas {
  margin: auto;
  max-width: 1920px;
  width: 100%;
  text-align: center;
  height: 37px;
  color: #9EA6AF;
  background-color: #272C31;
  border-top: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
}

.btn-outline-secondary {
  background-color: transparent !important;
  border: none !important;
}

.btn-outline-secondary :hover {
  color: #ffffff !important;
}

.estilo_icono_boton_visor {
  color: #9EA6AF;
}

.estilo_separador_boton_visor {
  display: inline;
  color: #616161;
}

/* .estilo_contenidos {
  font-family: 'Montserrat', sans-serif;
} */

/* .estilo_equipo {
  font-family: 'Montserrat', sans-serif;
  text-align: justify;
} */

/* .estilo_actividades {
  text-align: justify;
  max-width: 1920px;
  font-family: 'Montserrat', sans-serif;
} */

/* .estilo-breadcrumbs {
  font-family: 'Archivo Narrow', sans-serif;
  font-size: 14px;
}

.estilo-breadcrumbs a {
  text-decoration: none;
  color: #000000;
} */

/* .estilo_titulo_paginas {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  color: #37E0A5;
  text-shadow: 1px 1px 2px rgb(0, 0, 0), 0 0 1em rgb(1, 10, 6), 0 0 0.2em rgb(4, 4, 12);
 }

.estilo_subtitulo_paginas {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  color: #AFD5A8;
  text-shadow: 1px 1px 2px rgb(0, 0, 0), 0 0 1em rgb(1, 10, 6), 0 0 0.2em rgb(4, 4, 12);
} */

/* .estilo_div_header_visor_fichas {
  width: 100%;
  height: 30px;
  background-color: #AFD5A8;
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  padding-top: 5px;
} */

/* .estilo_titulo_pagina_inicio {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  color: #37E0A5;
  text-shadow: 1px 1px 2px rgb(0, 0, 0), 0 0 1em rgb(1, 10, 6), 0 0 0.2em rgb(4, 4, 12);
  text-align: center;
 } */

/*  .estilo_subtitulo_pagina_inicio {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  color: #AFD5A8;
  text-shadow: 1px 1px 2px rgb(0, 0, 0), 0 0 1em rgb(1, 10, 6), 0 0 0.2em rgb(4, 4, 12);
  text-align: center;
} */